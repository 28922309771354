import journalLifeImage from '../assets/images/projects/journallife1.png';
import LiteFreshVoice from '../assets/images/projects/litefresh.png';
import Beatskillz from '../assets/images/projects/beatskillz.png';
import Suprajit from '../assets/images/projects/suprajit.png';
import SiestaKeyChamber from '../assets/images/projects/siestakeychamber.png';
import LogHeadsCenter from '../assets/images/projects/logheads.png';
import Diocal from '../assets/images/projects/diocese.png';
import Grailpay from '../assets/images/projects/grailpay.png';
import Topel from '../assets/images/projects/fandd.png';
import Millcreek from '../assets/images/projects/millcreek.png';
import SandDollarOne from '../assets/images/projects/sanddollarone.png';
import Omnisys from '../assets/images/projects/omnisys.png';
import Shipit from '../assets/images/projects/shipit.png';
import Reimbursement from '../assets/images/projects/reinbursement.png';
import Vectura from '../assets/images/projects/vectura.png';
import DFO from '../assets/images/projects/clockoff.png';
import VoicePing from '../assets/images/projects/voiceping.png';
import Dint from '../assets/images/projects/dint.png';
import VersusStocks from '../assets/images/projects/versusstocks.png';
import grailpayWidgetApp from '../assets/images/projects/grailpay_widget.png'
import Carnawash from '../assets/images/projects/carnawash.png';
import Docodemo from '../assets/images/projects/docodemo.png';
import TheArtCare from "../assets/images/projects/theartcare.png";
import Connectar from "../assets/images/projects/theconnectar.png";
import Willhelm from "../assets/images/projects/willhelm.png";
import healthCare from "../assets/images/projects/healthcare.png";
import Dental from "../assets/images/projects/dental.png";
import MIMOR from "../assets/images/projects/homepage-mimor.png";
import Phoenixlamps from "../assets/images/projects/phoenixlamps.png";
import VIPDNSCLUB from "../assets/images/projects/vipdnsclub.png";
import Moxymix from "../assets/images/projects/moxymix.png";
import LuxuryPickleball from "../assets/images/projects/luxurypickleball.png";
import FREDHOME from "../assets/images/projects/fred-home.png";
import MySpy from "../assets/images/projects/myspy.png";
import Bould from "../assets/images/projects/bould.png";
import OrderManagement from "../assets/images/projects/ordermanagement.png";
import Wholesaler from "../assets/images/projects/wholesaler.png"
import Barber from "../assets/images/projects/barber-supply-store.png"
export const ProjectDetails = [

    {
        name: 'Beatskillz',
        lang: 'wordpress',
        classes:'wordpress',
        image: Beatskillz,
        desc: 'Beatskillz has the sound and the approach that we immediately connected with; instant inspiration and unexpected gratification.',
        Website: 'https://www.beatskillz.com/',
        status: 'completed',
        location: "USA",
        show_website: true,
        eCommerce_project:true,
    },
    {
        name: 'Suprajit',
        lang: 'wordpress',
        classes:'wordpress',
        image: Suprajit,
        desc: 'The Suprajit Group is a global leader in the automotive cable and halogen bulb industry. With a competitive manufacturing base in India, UK, US and Mexico, along with our technical and logistical supports worldwide, the group provides the optimal product development and manufacturing solutions to its domestic and international customers.',
        Website: 'https://suprajit.com/',
        status: 'In progress',
        location: 'USA',
        show_website: true,
        Crm_project:true,
    },
    {
        name: 'SiestaKeyChamber',
        lang: 'Ruby on Rails, Wordpress',
        classes:'wordpress, Ruby on Rails',
        image: SiestaKeyChamber,
        desc: 'The Siesta Key Chamber of Commerce is a membership-based, 501(c)(6) organization. Consequently, the Chamber is lead by a 15-member board of directors. Each board member serves a three year term of service to the Chamber.',
        Website: 'https://www.siestakeychamber.com/',
        status: 'Completed',
        location: 'USA',
        show_website: true,
        eCommerce_project:true,
    },
    {
        name: 'LogHeadsCenter',
        lang: 'Magento, Wordpress ,laravel',
        classes:'wordpress, laravel',
        image: LogHeadsCenter,
        desc: 'One of the largest manufacturers of Log Furniture in the U.S., we were approached by HGTV Extreme Makeover. They were interested in Tommy designing custom furniture for a few of their episodes and we were thrilled they chose us and so honored to have helped out families in need.',
        Website: 'https://logheadshomecenter.com/',
        status: 'Completed',
        location: 'USA',
        show_website: true,
        eCommerce_project:true,
    },
    {
        name: 'Diocal',
        lang: 'Drupal, wordpress',
        classes:'Drupal, wordpress',
        image: Diocal,
        desc: 'Embodied Justice, Church Vitality, Rooted Spirituality, Transparent and Accountable Leadership, and Inclusive Community. The Beloved Community, like the Kingdom of God Jesus references in the gospels, is something we catch glimpses of here and now!',
        Website: 'https://www.diocal.org/',
        status: 'In progress',
        location: 'USA',
        show_website: true,
        Crm_project:true,
    },
    {
        name: 'Grailpay',
        lang: 'Reactjs, Ruby on Rails, Shopify, Firebase, Laravel, Wordpress plugin',
        classes:'Reactjs, Ruby on Rails, Shopify, Firebase, Laravel, Wordpress plugin',
        image: Grailpay,
        desc: 'GrailPay is the first payments platform that turns payments from a cost center to a profit center. It is money movement, customer onboarding, and financing, all in one.',
        Website: 'https://grailpay.com/',
        status: 'In progress',
        location: 'USA',
        show_website: true,
        Fintech_project:true
    },
    {
        name: 'Topel & DiStasi',
        lang: 'Wordpress',
        classes:'wordpress',
        image: Topel,
        desc: 'We help our clients pursue their life goals through comprehensive wealth management and financial planning expertise.',
        Website: 'https://td-wm.com/',
        status: 'Completed',
        location: 'USA',
        show_website: true,
        Crm_project:true,

    },
    {
        name: 'journalLife',
        lang: 'Ruby on Rails',
        classes:'Ruby on Rails',
        image: journalLifeImage,
        desc: 'JournalLife is award-winning online journal to write, reflect and review your journal securely and privately. Add depth and new perspective to your journaling. JournalLife is simple, yet sophisticated journal software, created by long-time journal writers and endorsed by journal therapists, best-selling authors, and academic researchers worldwide.',
        Website: 'https://app.journallife.me/',
        status: 'In progress',
        location: "USA",
        show_website: true,
        Social_project:true,

    },
    {
        name: 'LiteFreshVoice',
        lang: 'Reactjs, Ruby on Rails, Firebase,nodejs',
        classes:'Reactjs, Ruby on Rails, Firebase,nodejs',
        image: LiteFreshVoice,
        desc: 'Internal and external meetings, web business negotiations, and web interview webinars can all be done with LFV!.',
        Website: 'https://www.freshvoice.net/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        Telecommunication_project:true,

    },
    {
        name: 'SandDollarOne',
        lang: 'Ruby on Rails',
        classes:'Ruby on Rails',
        image: SandDollarOne,
        desc: 'Let us guide you to your perfect rental! We believe we have the best team on 30A that will lead you to your dream vacation! We love helping guests create memorable, fun vacations by providing them with beautiful beach homes. With professional local staff on call 24/7, we are here to help you have the best time on the sugar white sands and emerald waters! Our superior homes and serivce will leave you speechless!',
        Website: 'https://www.sanddollarone.com/',
        status: 'Completed',
        location: "Florida, US",
        show_website: true,
        eCommerce_project:true,

    },
    {
        name: 'VoicePing',
        lang: 'Reactjs, Ruby on Rails, Firebase',
        classes:'Reactjs, Ruby on Rails, Firebase',
        image: VoicePing,
        desc: 'VoicePing is used as a virtual office tools to maximize telework productivity. VoicePing is being used as a tool to check the productivity of members using the time tracking function.',
        Website: 'https://voice-ping.com/',
        status: 'Completed',
        location: "Japan",
        show_website: true,
        Telecommunication_project:true,
    },
    {
        name: 'Millcreek',
        lang: 'Laravel, Vue.js',
        classes:'Laravel, Vue.js',
        image: Millcreek,
        desc: 'Millcreek Gardens was established in 1978, and we grow high quality container-grown Perennials, Herbs, Annuals, Ornamental Grasses, Shrubs, Ferns, and Vines.',
        Website: 'http://millcreekplants.com/',
        status: 'In progress',
        location: "USA",
        show_website: true,
        eCommerce_project:true,

    },
    {
        name: 'Omnisys',
        lang: 'Laravel',
        classes:'Laravel',
        image: Omnisys,
        desc: 'OmniSYS is a technology company with 25+ years of experience working with pharmacies, payers, providers and pharmaceutical manufacturers. We leverage this unique market insight along with our pharmacy workflow expertise to deliver valuable solutions that help pharmacies thrive in the changing pharmacy landscape.',
        Website: 'https://www.omnisys.com/',
        status: 'In progress',
        location: "USA",
        show_website: true,
        healthCare_project:true

    },
    {
        name: 'Shipit',
        lang: 'Laravel, Vue.js',
        classes:'Laravel, Vue.js',
        image: Shipit,
        desc: 'Where Shippers and Transporters Connect.Our mission is to provide you with an alternative cost-efficient marketing platform for transporting.',
        Website: 'http://www.shipitweb.com/',
        status: 'In progress',
        location: "USA",
        show_website: true,
        Crm_project:true,

    },
    {
        name: 'Vectura',
        lang: 'Reactjs, Nodejs, Nextjs, ChatGPT',
        classes:'Reactjs, Nodejs, Nextjs, ChatGPT',
        image: Vectura,
        desc: 'Your one-stop-shop for top-notch design solutions. At Vectura, you can understand the importance of having a well-designed brand, logo, website, or any other form of visual representation.',
        Website: 'https://www.vectura.io/',
        status: 'Completed',
        location: "Japan",
        show_website: false
    },
    {
        name: 'Docodemo',
        lang: 'Reactjs',
        classes:'Reactjs',
        image: Docodemo,
        desc: 'By using DOKODEMO Door\'s free library of content, anyone can easily create their own World and hold an event.Create a unique World full of realism from more than 1000 locations from 100 countries around the globe!',
        Website: 'https://dokodemodoors.com/en/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        Telecommunication_project:true,

    },
    
    {
        name: 'EO Reimbursement',
        lang: 'Django, Python',
        classes:'Django, Python',
        image: Reimbursement,
        desc: 'The EO Reimbursement form is a web application built using Django for both the backend and frontend. The form is designed to handle the submission of reimbursement requests for various expenses.Clear instructions and validation messages guide users through the submission process',
        Website:'http://eolosangeles.net/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        Crm_project:true,

    },
    {
        name: 'Dint',
        lang: 'Django, Python, Firebase',
        classes:'Django, Python, Firebase',
        image: Dint,
        desc: 'Dint is a social platform built using Django and React, where users can connect with each other. It features subscription-based posts implemented through smart contracts and digital wallets, ensuring user privacy. The backend utilizes Django Rest Framework for API development, and PostgreSQL is used for database management.',
        Website:'',
        status: 'Completed',
        location: "USA",
        show_website: false,
        Social_project:true,

    },
    {
        name: 'DFO Scheduler',
        lang: 'Nodejs,React Native',
        classes:'Nodejs,React Native',
        image: DFO,
        desc: 'A website to record and manage time for your various jobs.',
        Website: 'https://app.dfobobcatandcartage.com/',
        status: 'Completed',
        location: "Australia",
        show_website: true,
        Crm_project:true,

    },
    {
        name: 'Carnawash app',
        lang: 'Expo React Native',
        classes:'React Native',
        image: Carnawash,
        desc: 'Carnawash is focused on providing the most convenient and ecofriendly car wash experience available. We believe that car washing needs to be more convenient for the customer. We want to also offer outstanding opportunities for people to work for Carnawash in whatever capacity suits them and their lifestyle.',
        Website:'',
        status: 'Completed',
        location: "Australia",
        show_website: false,
        eCommerce_project:true,

    },
    {
        name: 'VersusStocks',
        lang: 'EmberJs, Firebase',
        image: VersusStocks,
        classes:'EmberJs, Firebase',
        desc: 'Develops and operates a business-to-business software platform in the United States and Canada. The company operates eXtreme Engagement Online, a platform that allows live event producers, professional sports franchises, video game publishers and developers, live event producers, and professional sports franchises, as well as other interactive media content creators, to offer in-game prizing and rewards based on the completion of in-content challenges alongside other user engagement tools.',
        Website: 'https://versusstocks.com/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        Fintech_project:true

    },
    {
        name: 'Grailpay Widget App',
        lang: 'Shopify',
        classes:'Shopify',
        image: grailpayWidgetApp,
        desc: 'GrailPay offers pay by bank (ACH) to save merchants 75%+ on payment processing costs at e-commerce checkout. GrailPay lowers the cost of payments (processing at 0.95% / transaction), offers market-leading fraud detection & dispute resolution, and provides shoppers highly rewarding payment at checkout.',
        Website: 'https://apps.shopify.com/grailpay-widget-app',
        status: 'Completed',
        location: "USA",
        show_website: true,
        eCommerce_project:true,

    },
    {
        name: 'The art of care',
        lang: 'Reactjs',
        classes:'Reactjs',
        image: TheArtCare,
        desc: '"The Art of Care" is a compelling project that delves into the intricate experiences of mental health and human connection through two distinct editions of a powerful narrative',
        Website: 'https://theartofseries.com/',
        status: 'In progress',
        location: "USA",
        show_website: true,
        eCommerce_project:true
    },
    {
        name: 'The Connectar',
        lang: 'Reactjs,Nodejs',
        classes:'Reactjs,Nodejs,',
        image: Connectar,
        desc: 'The employee connector project regularly checks how well employees match predefined criteria like personality, intelligence, hobbies, and values. Each month, it uses automated scheduling to ensure these assessments are up-to-date and accurate. This helps in understanding employee fit and improving team dynamics efficiently.',
        Website: 'http://theconnectar.com/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        Crm_project:true,
    },
    {
        name: 'Willhelm Schweizer Zinnmanufaktur',
        lang: 'Django, Python',
        classes:'Django,Python',
        image: Willhelm,
        desc: 'The Wilhelm Schweizer Zinnmanufaktur has been dedicated to the art of pewter craftsmanship for centuries. As a family-owned business based in Diessen am Ammersee, their rich history and commitment to quality have made them a leading manufacturer of uniquely crafted pewter figurines. Each piece is carefully molded, cast, deburred, and painted, inspired by traditional German folklore and enchanting milestones, making them unique treasures to be passed down to future generations. To bring the exquisite craftsmanship of Wilhelm Schweizer to a global audience, they have developed an e-commerce website using a robust Django fullstack tech stack. This platform features a seamless shopping experience with HTML5, CSS3, JavaScript, Django, and PostgreSQL, ensuring a smooth and enjoyable customer experience.',
        Website: '',
        status: 'Completed',
        location: "USA",
        show_website: false,
        Crm_project:true,
    },
    {
        name: 'Patient Appointment System',
        lang: 'Reactjs, Django, Python',
        classes:'Reactjs, Django, Python',
        image: healthCare,
        desc: 'The healthcare appointment system makes scheduling easy and secure while following HIPAA rules. It lets patients book and manage appointments online, sends reminders, and keeps their data safe. The system ensures only authorized people can access sensitive information and tracks all activities for security.',
        Website: '',
        status: 'In progress',
        location: "India",
        show_website: false,
        healthCare_project:true
    },
    {
        name: 'Dental Practice System',
        lang: 'Reactjs, Nodejs',
        classes:'Reactjs,Nodejs,',
        image: Dental,
        desc: 'At Dental Practice System, we are dedicated to providing exceptional dental services to help you maintain a healthy and beautiful smile. Our comprehensive dental care includes both preventive and professional treatments to ensure your oral health is always at its best.',
        Website: '',
        status: 'In progress',
        location: "India",
        show_website: false,
        healthCare_project:true
    },
    {
        name: 'MIMOR',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: MIMOR,
        desc: 'MIMOR is a cloud-based strata-building communication and management system that simplifies strata living by enabling residents, owners corporations and strata managers to interact, communicate and engage quickly & easily.',
        Website: 'https://mimor.com.au/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        realestate_project:true
    },
    {
        name: 'Phoenixlamps',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: Phoenixlamps,
        desc: 'Phoenix Lamps is part of Suprajit Group which comprises of Suprajit Engineering Limited, Suprajit Automotive Limited, Suprajit Europe Limited, and Phoenix Lamps Limited. The group is a global leader in the automotive cable industry.',
        Website: 'https://phoenixlamps.co.in/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        Crm_project:true,
    },
    {
        name: 'VIPDNSCLUB',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: VIPDNSCLUB,
        desc: 'VIPDNSclub is a SmartDNS service that allows you to unblock Geo-blocked websites and streaming services such as Netflix, Hulu, BBC iPlayer, HBO Max, and many others.',
        Website: 'https://vipdnsclub.com/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'Moxymix',
        lang: 'Wordpress',
        classes:'Wordpress,',
        image: Moxymix,
        desc: 'Discover a wide range of home furnishings and decor at our eCommerce store. Browse through our selection of chairs, area rugs, bar stools, benches, drapery, lighting, lounge chairs, ottomans, stools, pillows, sofas, sectionals, and task and side chairs. Find everything you need to enhance your living space with style and comfort.',
        Website: 'https://moxymix.marriott.com/login/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        eCommerce_project:true,
    },
    {
        name: 'Luxury Pickleball',
        lang: 'Shopify',
        classes:'Shopify,',
        image: LuxuryPickleball,
        desc: 'We are coming together to share a healthy, curated, luxury lifestyle. This includes delivering elevated products to other pickleball enthusiasts who wish to join our community striving for extraordinary life experiences. As we love traveling and surrounding ourselves with other like-minded players, we want you to enjoy the best vacations, while playing an exceptional sport that will benefit you physically, cognitively and socially.',
        Website: 'https://luxurypickleball.com/',
        status: 'Completed',
        location: "USA",
        show_website: true
    },
    {
        name: 'FREDHOME',
        lang: 'Shopify',
        classes:'Shopify,',
        image: FREDHOME,
        desc: 'Fred Home is a luxury soft furnishings and homewares brand.Fred sources luxury fabrics from around the globe and transforms them into carefully-curated, limited-edition collections of indoor and outdoor cushions, table linen, napkins and more. Eschewing trends and common palettes, our collections are made in small batches using the highest quality fabrics, cleverly designed to make a standalone statement or effortlessly and joyfully combine.',
        Website: 'https://fredhome.com.au/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        eCommerce_project:true,
    },
    {
        name: 'MySpy',
        lang: 'Reactjs,Nodejs',
        classes:'Reactjs,Nodejs,',
        image: MySpy,
        desc: 'Myspy Security is Melbournes expert in industry-leading security camera technology, temporary security camera surveillance, CCTV and 24/7 back-to-base monitoring.',
        Website: 'http://13.51.114.60/login',
        status: 'In progress',
        location: "USA",
        show_website: true,
        Crm_project:true,
    },
    {
        name: 'Bould',
        lang: 'Reactjs, Python',
        classes:'Reactjs, Python',
        image: Bould,
        desc: 'BouldHQ is an innovative platform transforming online shopping with advanced technology. It offers a personalized, immersive shopping experience for fashion enthusiasts, featuring a true-to-size 3D avatar to try on clothes virtually, eliminating the hassle of returns. With personalized recommendations, BouldHQ acts as your personal stylist, helping you find the perfect fit and style from home. Experience a seamless, confident shopping journey where convenience meets innovation, ensuring every purchase is an adventure. Partner with BouldHQ to embrace the future of eCommerce.',
        Website: 'https://bouldhq.com/',
        status: 'Completed',
        location: "USA",
        show_website: true,
        eCommerce_project:true
    },
    {
        name: 'Order Management',
        lang: 'React Native',
        classes:'React Native',
        image: OrderManagement,
        desc: 'The restaurant order management app you are working on manages customer orders, confirms them, and offers payment options via online payment gateways or cash. After payment, the app provides a receipt.',
        Website:'',
        status: 'In progress',
        location: "Australia",
        show_website: false,
        eCommerce_project:true,
        Crm_project:true,
    },
    {
        name: 'Wholesaler Elite',
        lang: 'Shopify',
        classes:'Shopify',
        image: Wholesaler,
        desc: ' The commitment to supporting future sailing champions and outdoor enthusiasts by offering North Americas largest selection of high-quality camping, hiking, and boating products. It emphasizes the careful selection and rigorous testing of brands to ensure reliability and durability, making it a trusted source for high-performance outdoor gear. Whether you are an avid camper, hiker, or seasoned sailor, this store promises everything you need for your adventures. The message invites customers to explore the extensive collection and confidently prepare for their next great journey.',
        Website: 'https://www.wholesalerelite.com/',
        status: 'Completed',
        location: "America",
        show_website: true,
        eCommerce_project:true,

    },
    {
        name: 'Barber Supply Store',
        lang: 'Shopify',
        classes:'Shopify',
        image: Barber,
        desc: ' The importance of high-quality tools and equipment in providing exceptional service in a barbershop or salon. It highlights how professional-grade products, such as hairdresser and barber chairs, contribute to an organized and appealing environment that customers will appreciate. The message encourages potential clients to explore the range of products available at John Barber, which are designed for comfort, durability, and style, ultimately enhancing the overall customer experience.',
        Website: 'https://johnbarbersons.com/en',
        status: 'Completed',
        location: "Germany",
        show_website: true,
        eCommerce_project:true,

    },
]