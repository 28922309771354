import React from "react";
import footerImg from '../assets/images/pngimages/footer-map.png';
import logo from '../assets/images/logo.png';
import { Link } from "react-router-dom";
import { SiUpwork } from "react-icons/si";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="logo-footer">
                                <img src={logo} width="110px" />
                                <p>We offer IT services to our clients and create customized web solutions. Our experts
                                    ensure
                                    that the work we
                                    have done has a positive impact on our client’s business along with a good balance
                                    between
                                    quality and security.</p>
                                <div className="social-links">
                                    <ul className="p-0 d-flex">
                                        <li><a href="https://www.facebook.com/squadminds" target="_blank"><i
                                            className="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.upwork.com/ag/squadminds/" target="_blank"><SiUpwork /></a></li>
                                        <li><a href="https://in.linkedin.com/company/squadmindspvtltd" target="_blank"><i
                                            className="fa-brands fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="explore">
                                <h3>Explore</h3>
                                <ul className="p-0 ps-3">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="address">
                                <h3>Address</h3>
                                <ul className="p-0">
                                    <li>
                                        <div className="d-flex">
                                            <div className="footer-icon"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                            </div>
                                            <a href=''>E-190, 6th Floor, Industrial Area, Sector 74, Sahibzada Ajit
                                                Singh
                                                Nagar,
                                                Punjab 160055</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex">
                                            <div className="footer-icon"><i className="fa fa-phone" aria-hidden="true" style={{color:'#fe7126'}}></i>
                                            </div>
                                            <a href="tel:+911724026667">0172-4102716</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex">
                                            <div className="footer-icon"><i className="far fa-envelope"></i>
                                            </div>
                                            <a href="mailto:info@squadminds.com">info@squadminds.com</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-mapImg">
                        <img src={footerImg} />
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer