import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Publicroutes from '../routes/Routes';
import Layout from './Layout';

function App() {
  return (
    <Router>
      <Layout>
        <Publicroutes />
      </Layout>
    </Router>
  );
}

export default App;
