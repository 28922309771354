import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../components/Home';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';
import Career from '../components/Career';


const Publicroutes = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/portfolio" element={<Portfolio />} />
                <Route exact path="/contact-us" element={<Contact />} />
                <Route exact path="/careers" element={<Career />} />
            </Routes>
        </>
    );
};

export default Publicroutes;
