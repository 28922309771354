import React, { useState, useEffect } from "react";

const Career = () => {
    const [activeItem, setActiveItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleItemClick = (item) => {
        setActiveItem(item === activeItem ? null : item);
    };
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        setTimeout(function () {
          window.scrollTo(0, 0);
          AOS.init({ duration: 500, delay: 50 });
        }, 1000);
      }, []);
    return (
        <>
            {showButton && (
                <a className="scroll-to-top-button" onClick={scrollToTop}>
                </a>
            )}
            <section className="carrer">
                <div className="container">
                    <div >
                        <div className="accordion" id="accordionExample">
                            {/* Accordion Items */}
                            <div className="accordion-item" data-aos="fade-up">
                                <h2 className="accordion-header" id="headingOne">

                                    <button
                                        className="accordion-button"
                                        type="button"
                                        onClick={() => handleItemClick(1)}
                                        aria-expanded={activeItem === 1}
                                        aria-controls="collapseOne"
                                    >
                                        <div>
                                            <h2 className="carrer-heading m-0">Online Bidder (Level 1)</h2>
                                            <h5 className="experience"><span><i className="fa fa-briefcase" aria-hidden="true"></i>:</span>
                                                0-1 Years | <span><i className="fa fa-map-marker" aria-hidden="true"></i>:</span> Mohali
                                                | <span><i className="fa fa-inr" aria-hidden="true"></i>:</span> As per skills /
                                                Negotiable </h5>
                                            <p>We are looking for Online Bidder,Data Entry operator who is enthusiastic and
                                                goal-oriented. The person should have Really good English communication and
                                                writing skills. The person will also be responsible for interacting with clients
                                                through chat and must be good at writing proposals.</p>

                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className={`accordion-collapse collapse ${activeItem === 1 ? 'show' : ''}`}
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <h3 className="online-bider">Requirements:</h3>
                                        <ul>
                                            <li>Bachelor’s degree is required.</li>
                                            <li>Excellent communication & verbal skills.</li>
                                            <li>High attention to detail and a focus on fact-based decision making.</li>
                                            <li>Work with senior team members to identify and manage risks.</li>
                                            <li>Foster a collaborative environment within the organization.</li>
                                        </ul>
                                        <h3 className="online-bider">Roles and Responsibilities:</h3>
                                        <ul>
                                            <li>Should be able to generate leads on Upwork.</li>
                                            <li>Should have good communication skills and has got IT/CS background will be a great
                                                plus.
                                            </li>
                                            <li>Should be able to find new clients, get deals finalized, and coordinate with the
                                                technical team
                                                on deliverables.</li>
                                            <li>Should be responsible for bidding of web development & Designing and Digital
                                                marketing
                                                projects
                                                having basic idea about that and updated on the new technologies and bidding online
                                                will
                                                be a
                                                great addition.</li>
                                            <li>Candidates should be willing to learn to generate business</li>
                                            <li>Should be a self-starter & independent pro-active, persuasive orientation.</li>
                                            <li>Self-driven and willing to take initiative.</li>
                                            <li>Oversee the sales process to attract new clients.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Repeat the above structure for other accordion items */}
                            <div className="accordion-item" data-aos="fade-up">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        onClick={() => handleItemClick(2)}
                                        aria-expanded={activeItem === 2}
                                        aria-controls="collapseOne"
                                    >
                                        <div>
                                            <h2 className="carrer-heading m-0">Online Bidder (Level 2)</h2>
                                            <h5 className="experience"><span><i className="fa fa-briefcase" aria-hidden="true"></i>:</span>
                                                1-3 Years | <span><i className="fa fa-map-marker" aria-hidden="true"></i>:</span> Mohali
                                                | <span><i className="fa fa-inr" aria-hidden="true"></i>:</span> 25,000 - 45,000
                                            </h5>
                                            <p>The ideal candidate will be responsible for online bidding on Upwork, Guru,
                                                Freelancer, etc. You will generate new business relationships, identify business
                                                opportunities, and close business deals for a wide range of Digital Services.
                                                You need to analyze business Challenges, identify problems and be able to
                                                propose solutions for the same. The candidate must have experience with Lead
                                                generator, Calling, Drafting of proposals, Client interaction, Negotiation,
                                                Follow ups and also coordination with the technical team.</p>
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className={`accordion-collapse collapse ${activeItem === 2 ? 'show' : ''}`}
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <h3 className="online-bider">The candidate's day-to-day responsibilities will include:-</h3>
                                        <ul>
                                            <li>Create Business Proposals, Presentations, Slides, and other documents as required by
                                                a potential client.</li>
                                            <li>Generating leads through social media.</li>
                                            <li>Must be able to handle the entire pre-sales cycle of Lead Generation, Appointment
                                                Fixing, presenting Company Profile & relevant Product Demo, Follow-Ups with
                                                prospects, Proposal Submission to prospective clients.</li>
                                            <li>Researching the market and identifying potential target customers, Converting
                                                prospective clients into customers using IT and sales acumen</li>
                                            <li>Organizing current and prospective customer data using Upwork software</li>
                                        </ul>
                                        <h3 className="online-bider">Qualifications</h3>
                                        <ul>
                                            <li>Good at negotiations to acquire customer</li>
                                            <li>Experience of 1-3 yrs is required in online bidding</li>
                                            <li>Excellent written and verbal communication skills</li>
                                            <li>Required skills -Business development, bidding, Upwork, freelancer, elance, guru.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" data-aos="fade-up">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        onClick={() => handleItemClick(3)}
                                        aria-expanded={activeItem === 3}
                                        aria-controls="collapseOne"
                                    >
                                        <div>
                                            <h2 className="carrer-heading m-0">Mobile Application Developer - React Native</h2>
                                            <h5 className="experience"><span><i className="fa fa-briefcase" aria-hidden="true"></i>:</span>
                                                1-4 Years | <span><i className="fa fa-map-marker" aria-hidden="true"></i>:</span> Mohali
                                                | <span><i className="fa fa-inr" aria-hidden="true"></i>:</span> As per skills /
                                                Negotiable
                                            </h5>
                                            <p>We are looking to hire a full time React Native Developer with 1- 4 years of
                                                experience and work experience with React native.
                                                As per your years of experience, you should have hands on workability in:</p>
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className={`accordion-collapse collapse ${activeItem === 3 ? 'show' : ''}`}
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <h3 className="online-bider">Job description</h3>
                                        <ul style={{ paddingLeft: '20px' }}>
                                            <li>Ability to build React Native iOS / Android applications</li>
                                            <li>Architect, build and maintain excellent React Native applications with clean code.
                                            </li>
                                            <li>Implement pixel perfect UI's that match designs.</li>
                                            <li>Implement clean, modern, smooth animations and transitions that provide an excellent
                                                user experience.</li>
                                            <li>Integrate third-party APIs.</li>
                                            <li>Release applications to the Apple and Google Play stores.</li>
                                            <li>Work with native modules when required.</li>
                                            <li>Industrial experience in mobile programming and mobile hybrid technologies.</li>
                                            <li>Excellent knowledge of RESTful APIs to connect the applications to back-end
                                                services.</li>
                                            <li>Soft Skills: Strong communication and interpersonal skills. Ability to work in a
                                                fast-paced, high-energy environment.</li>
                                        </ul>
                                        <div className="group-btns">
                                            <button>javaScript</button>
                                            <button>React Native</button>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career