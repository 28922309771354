import React, { useState, useEffect, useCallback } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ProjectDetails } from "./ProjectDetails";
import { useLocation, useNavigate } from 'react-router-dom';
import Projmodal from "./modals/Projmodal";
import AOS from "aos";
import "aos/dist/aos.css";

const tabsArray = [
    { eventKey: 'All', title: 'All', filter: () => true },
    { eventKey: 'Healthcare', title: 'Healthcare', filter: project => project.healthCare_project },
    { eventKey: 'E-commerce', title: 'E-commerce', filter: project => project.eCommerce_project },
    { eventKey: 'Telecommunication', title: 'Telecommunication', filter: project => project.Telecommunication_project },
    { eventKey: 'Fintech', title: 'Fintech', filter: project => project.Fintech_project },
    { eventKey: 'CRM', title: 'CRM', filter: project => project.Crm_project },
    { eventKey: 'RealEstate', title: 'Real Estate', filter: project => project.realestate_project },
    { eventKey: 'social', title: 'Social', filter: project => project.Social_project },
];

const secondTabArray = [
    { eventKey: 'All', title: 'All', filter: () => true },
    { eventKey: 'wordpress', title: 'Wordpress', filter: project => project.classes?.toLowerCase().includes('wordpress') },
    { eventKey: 'ruby', title: 'Ruby on Rails', filter: project => project.classes?.toLowerCase().includes('ruby') },
    { eventKey: 'laravel', title: 'Laravel', filter: project => project.classes?.toLowerCase().includes('laravel') },
    { eventKey: 'reactjs', title: 'Reactjs', filter: project => project.classes?.toLowerCase().includes('reactjs') },
    { eventKey: 'native', title: 'React Native', filter: project => project.classes?.toLowerCase().includes('native') },
    { eventKey: 'python', title: 'Python/Django', filter: project => project.classes?.toLowerCase().includes('python') || project.classes?.toLowerCase().includes('django') },
    { eventKey: 'node', title: 'Node js', filter: project => project.classes?.toLowerCase().includes('node')},
    { eventKey: 'firebase', title: 'Firebase', filter: project => project.classes?.toLowerCase().includes('firebase')},
    { eventKey: 'shopify', title: 'Shopify', filter: project => project.classes?.toLowerCase().includes('shopify')},
    { eventKey: 'vue.js', title: 'Vue.js', filter: project => project.classes?.toLowerCase().includes('vue.js')}
];

const Portfolio = () => {
    const [topKey, setTopKey] = useState('All');
    const [secondKey, setSecondKey] = useState('All');
    const [showButton, setShowButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [projDetails, setProjDetails] = useState();
    const [filteredProjects, setFilteredProjects] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setShowButton(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const tab = tabsArray.find(tab => tab.eventKey === topKey);
        const topFilteredProjects = ProjectDetails.filter(project => tab ? tab.filter(project) : true);
        
        const secondTab = secondTabArray.find(tab => tab.eventKey === secondKey);
        const finalFilteredProjects = topFilteredProjects.filter(project => secondTab ? secondTab.filter(project) : true);

        setFilteredProjects(finalFilteredProjects);
    }, [topKey, secondKey]);

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    const handleProjDetails = (item) => {
        setShowModal(!showModal);
        setProjDetails(item);
    };

    const handleCloseModal = () => setShowModal(false);
    
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const category = searchParams.get('category') || 'All';
        const subCategory = searchParams.get('subCategory') || 'All';
        setTopKey(category);
        setSecondKey(subCategory);
    }, [location.search]);

    
    useEffect(() => {
        const hash = location.hash.slice(1).split('#');
        const category = hash[0] || 'All';
        const subCategory = hash[1] || 'All';
        setTopKey(category);
        setSecondKey(subCategory);
    }, [location.hash]);

    const updateHash = useCallback((topKey, secondKey) => {
        const newHash = `#${topKey}${secondKey !== 'All' ? `#${secondKey}` : ''}`;
        navigate(`/portfolio${newHash}`);
    }, [navigate]);

    const handleTopTabSelect = useCallback((k) => {
        if (k !== topKey) {
            setTopKey(k);
            setSecondKey('All');
            updateHash(k, 'All');
        }
    }, [topKey, updateHash]);

    const handleSecondTabSelect = useCallback((k) => {
        if (k !== secondKey) {
            setSecondKey(k);
            updateHash(topKey, k);
        }
    }, [secondKey, topKey, updateHash]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            AOS.init({ duration: 700, delay: 50 });
        }, 100);
    }, []);

    return (
        <>
            {showButton && (
                <a className="scroll-to-top-button" onClick={scrollToTop}>
                </a>
            )}
            <div className="portfolio-main"  id="TopSection">
                <section className="portfolio-wrapper mt-5" data-aos="fade-up">
                    <Tabs id="top-tab-example" activeKey={topKey} onSelect={handleTopTabSelect} className="mb-0 text-center">
                        {tabsArray.map((tab) => (
                            <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}>
                            </Tab>
                        ))}
                    </Tabs>
                    <Tabs id="second-tab-example" activeKey={secondKey} onSelect={handleSecondTabSelect} className="mb-0 text-center">
                        {secondTabArray.map((tab) => (
                            <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}>
                            </Tab>
                        ))}
                    </Tabs>
                </section>
                <div className="container">
                    <div className="row">
                        {filteredProjects.length > 0 ? (
                            filteredProjects.map((item, index) => (
                                <div className="col-md-6 col-lg-4 mb-4" key={index} data-aos="fade-up">
                                    <div className="portfolio-tabs">
                                        <div className="projects-wrap position-relative" onClick={() => handleProjDetails(item)}>
                                            <div className="project-img portfolio portfolio-img">
                                                <img src={item.image} alt={item.name} style={{ cursor: 'pointer' }} />
                                            </div>
                                            <div className="portfolio-txt text-center">
                                                <h4>{item.name}</h4>
                                                <h5>{item.lang}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 text-center mt-5">
                                <h3>Project not available</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Projmodal modalVal={showModal} projDetails={projDetails} handleCloseModal={handleCloseModal} />
        </>
    );
};

export default Portfolio;
