import React from 'react';
import ReactDOM from 'react-dom'
import App from '../components/App';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
// );

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
      <App />,
    document.body.appendChild(document.createElement('div')),
  )
})
