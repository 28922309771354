import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaLocationDot, FaTag } from "react-icons/fa6";
import { HiOutlineGlobeAlt } from "react-icons/hi";

function Projmodal(props) {
    const { modalVal, projDetails,handleCloseModal } = props;
    const [show, setShow] = useState(modalVal);
    useEffect(() => {
        setShow(modalVal)
    }, [modalVal])
    const handleShow = () => {
        handleCloseModal(false);
    }
    return (
        <>
            <Modal
                show={show}
                // onHide={() => setShow(false)}
                onClick ={handleShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                style={{ marginTop: '100px',paddingLeft:"0px" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Project description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-12'>
                                <div className='case-studies-details-image'>
                                    <img src={projDetails?.image} alt='case-studies'/>
                                </div>
                                <div className='case-studies-details-desc'>
                                    <h3>Description</h3>
                                    <p>{projDetails?.desc}</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className='case-studies-sidebar-sticky'>
                                    <div className='case-studies-details-info'>
                                        <ul>
                                            <li>
                                                <div className='icon'>
                                                    <FaLocationDot />
                                                </div>
                                                <span>Location:</span>
                                                {projDetails?.location}
                                            </li>
                                            <li>
                                                <div className='icon'>
                                                    <FaTag />
                                                </div>
                                                <span>technologies: </span>
                                                {projDetails?.lang}
                                            </li>
                                            <li>
                                                <div className='icon'>

                                                    <FaLocationDot />
                                                </div>
                                                <span>Status:</span>
                                                {projDetails?.status}
                                            </li>
                                            <li>
                                               
                                               {projDetails?.show_website === true ? <>  <div className='icon'>
                                                    <HiOutlineGlobeAlt />
                                                </div> <span>Website:</span>
                                                <a class="website_link" href={projDetails?.Website} target="_blank">{projDetails?.Website}</a> </>:'' }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Projmodal;